import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import Button from 'components/shared/button'
import { Text } from 'components/shared/typography'

type Props = {
  text?: string
}

const StyledContainer = styled(Container)`
  display: none;
  align-items: center;
  justify-content: space-between;
  margin: 40px auto 25px auto;
  padding-left: ${({ theme }) => theme.container.paddingMD};
  padding-right: ${({ theme }) => theme.container.paddingMD};

  ${({ theme }) => theme.media.lg.min} {
    display: flex;
  }
`

const StyledButton = styled(Button)`
  margin-left: 20px;
  padding: 15px;
  font-size: 17px;
`

const InfoWithButton: React.FC<Props> = ({
  text = 'Jako producent ogrodzeń metalowych podchodzimy do pracy z ogromną pasją i zaangażowaniem , czego efektem są doskonałej jakości nowoczesne systemy ogrodzeniowe. Wspierani najnowocześniejszą technologią, tworzymy bezpieczne i trwałe ogrodzenia.',
}) => {
  return (
    <StyledContainer>
      <Text
        size={14}
        weight={400}
        themecolor="black"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <a href="/gdzie-kupic/">
        <StyledButton primary300 uppercase>
          zakup i realizacja
        </StyledButton>
      </a>
    </StyledContainer>
  )
}

export default InfoWithButton
