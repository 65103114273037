import React from 'react'
import styled, { css, keyframes } from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazyImage'
import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type GalleryItem = {
  img: ImageDataLike
  productImg: ImageDataLike
  descriptionTitle: string
  description: string
}

type Props = {
  gallery: GalleryItem[]
}

const slideFromTop = keyframes`
  0% {
    transform: translate(-50%, -150%);
  }

  100% {
    transform: translate(-50%, -50%);
  }

`

const slideFromBottom = keyframes`
  0% {
    transform: translate(-50%, 50%);
  }

  100% {
    transform: translate(-50%, -50%);
  }

`

const Section = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray100};
  padding: 20px 0;

  ${({ theme }) => theme.media.lg.min} {
    padding: 40px 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const TilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    overflow: hidden;
    border-radius: 30px;
    align-items: stretch;
  }

  ${({ theme }) => theme.media.xxl.min} {
    border-radius: 50px;
  }
`

const TileSingleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.gray300};
  overflow: hidden;
  border-radius: 15px;
  transition: 0.25s;

  & > ${Heading} {
    br {
      display: none;
    }
  }

  ${({ theme }) => theme.media.lg.min} {
    border-radius: 0;
    margin-bottom: 0;
    :nth-child(3),
    :nth-child(4) {
      flex-direction: column-reverse;
    }
  }
`

const HoverImgWrapperTop = styled.div<{ hidden: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -150%);
  width: 100%;
  height: 100%;
  z-index: 3;

  div {
    width: 100%;
    height: 100%;
  }

  &:hover {
    animation: ${slideFromTop} 300ms linear forwards;
  }

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`

const HoverImgWrapperBottom = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 100%;
  height: 100%;
  z-index: 3;

  div {
    width: 100%;
    height: 100%;
  }

  &:hover {
    animation: ${slideFromBottom} 300ms linear forwards;
  }
`

const ImgWrapper = styled.div`
  width: 100%;

  &:hover + ${HoverImgWrapperTop} {
    animation: ${slideFromTop} 300ms linear forwards;
  }
  &:hover ~ ${HoverImgWrapperBottom} {
    animation: ${slideFromBottom} 300ms linear forwards;
  }

  ${({ theme }) => theme.media.lg.min} {
    max-height: 300px;

    div {
      max-height: 300px;
    }
  }
`

const ProductWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 10px;
  ${({ theme }) => theme.media.sm.min} {
    padding: 20px 40px 15px 20px;
  }

  ${({ theme }) => theme.media.lg.min} {
    margin-bottom: auto;
  }
`

const ProductImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: max-content;
  min-width: 35%;
  max-width: 35%;

  // Gatsby image wrapper
  div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 10px;
    width: max-content;
  }

  ${({ theme }) => theme.media.lg.min} {
    div {
      padding-top: 0;
      height: 100%;
      max-height: 230px;
    }
  }
`

const ProductTextContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: fit-content;

  ${({ theme }) => theme.media.sm.min} {
    margin-left: 25px;
  }
`

const Tiles: React.FC<Props> = ({ gallery }) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <Container>
        <TilesWrapper>
          {gallery.map(
            ({ img, productImg, descriptionTitle, description }, index) => {
              const isBottomTile = lg && (index === 2 || index === 3)
              return (
                <TileSingleWrapper key={descriptionTitle}>
                  <ImgWrapper>
                    <LazyImage src={img} alt={descriptionTitle} />
                  </ImgWrapper>
                  <HoverImgWrapperTop hidden={isBottomTile}>
                    <LazyImage src={img} alt="" />
                  </HoverImgWrapperTop>
                  {isBottomTile && (
                    <HoverImgWrapperBottom>
                      <LazyImage src={img} alt={descriptionTitle} />
                    </HoverImgWrapperBottom>
                  )}
                  <ProductWrapper>
                    <ProductImgWrapper>
                      <LazyImage
                        src={productImg}
                        alt={descriptionTitle}
                        objectFit="contain"
                      />
                    </ProductImgWrapper>
                    <ProductTextContent>
                      <Heading
                        as="h6"
                        size={lg ? 24 : 28}
                        weight={500}
                        themecolor="black"
                        dangerouslySetInnerHTML={{ __html: descriptionTitle }}
                        margin={lg ? '20px' : '12px'}
                      />
                      <Text
                        size={14}
                        weight={500}
                        themecolor="black"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    </ProductTextContent>
                  </ProductWrapper>
                </TileSingleWrapper>
              )
            }
          )}
        </TilesWrapper>
      </Container>
    </Section>
  )
}

export default Tiles
