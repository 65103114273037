import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/Pages/MalaArchitektura/About'
import Tiles from 'components/layout/Sections/Pages/MalaArchitektura/Tiles'
import InfoWithButton from 'components/layout/InfoWithButton'

import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Mała architektura',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Mała architektura',
    },
  ],
}

const MalaArchitekturaPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.malaArchitektura
  const PAGE_SEO = data?.wpPage?.seo

  const GALLERY = React.useMemo(
    () => [
      {
        img: PAGE?.smallArchitectureRoof?.smallArchitectureRoofImg?.localFile
          ?.childImageSharp?.gatsbyImageData,
        productImg:
          PAGE?.smallArchitectureRoof?.smallArchitectureRoofPackshot?.localFile
            ?.childImageSharp?.gatsbyImageData,
        descriptionTitle:
          PAGE?.smallArchitectureRoof?.smallArchitectureRoofProductName,
        description:
          PAGE?.smallArchitectureRoof?.smallArchitectureRoofDescription,
      },
      {
        img: PAGE?.smallArchitectureShed?.smallArchitectureShedImg?.localFile
          ?.childImageSharp?.gatsbyImageData,
        productImg:
          PAGE?.smallArchitectureShed?.smallArchitectureShedPackshot?.localFile
            ?.childImageSharp?.gatsbyImageData,
        descriptionTitle:
          PAGE?.smallArchitectureShed?.smallArchitectureShedProductName,
        description:
          PAGE?.smallArchitectureShed?.smallArchitectureShedDescription,
      },
      {
        img: PAGE?.smallArchitectureCarport?.smallArchitectureCarportImg
          ?.localFile?.childImageSharp?.gatsbyImageData,
        productImg:
          PAGE?.smallArchitectureCarport?.smallArchitectureCarportPackshot
            ?.localFile?.childImageSharp?.gatsbyImageData,
        descriptionTitle:
          PAGE?.smallArchitectureCarport?.smallArchitectureCarportProductName,
        description:
          PAGE?.smallArchitectureCarport?.smallArchitectureCarportDescription,
      },
      {
        img: PAGE?.smallArchitectureSlide?.smallArchitectureSlideImg?.localFile
          ?.childImageSharp?.gatsbyImageData,
        productImg:
          PAGE?.smallArchitectureSlide?.smallArchitectureSlidePackshot
            ?.localFile?.childImageSharp?.gatsbyImageData,
        descriptionTitle:
          PAGE?.smallArchitectureSlide?.smallArchitectureSlideProductName,
        description:
          PAGE?.smallArchitectureSlide?.smallArchitectureSlideDescription,
      },
    ],
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.smallArchitectureImg?.localFile?.childImageSharp
            ?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.smallArchitectureImg?.localFile?.childImageSharp
            ?.gatsbyImageData
        }
        headingMobile={PAGE?.smallArchitectureTitle}
        headingDesktop={PAGE?.smallArchitectureTitle}
        breadcrumbs={breadcrumbs}
      />
      <About description={PAGE?.smallArchitectureDescription} />
      <Tiles gallery={GALLERY} />
      <InfoWithButton />
    </Layout>
  )
}

export default MalaArchitekturaPage

export const query = graphql`
  query MalaArchitekturaPage {
    wpPage(slug: { regex: "/mala-architektura/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      id
      malaArchitektura {
        fieldGroupName
        smallArchitectureDescription
        smallArchitectureTitle
        smallArchitectureRoof {
          smallArchitectureRoofProductName
          smallArchitectureRoofDescription
          smallArchitectureRoofImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 900
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          smallArchitectureRoofPackshot {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        smallArchitectureCarport {
          smallArchitectureCarportProductName
          smallArchitectureCarportDescription
          smallArchitectureCarportImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 900
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          smallArchitectureCarportPackshot {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        smallArchitectureShed {
          smallArchitectureShedProductName
          smallArchitectureShedDescription
          smallArchitectureShedImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 900
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          smallArchitectureShedPackshot {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        smallArchitectureSlide {
          smallArchitectureSlideProductName
          smallArchitectureSlideDescription
          smallArchitectureSlideImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 900
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          smallArchitectureSlidePackshot {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        smallArchitectureImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
